// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    background-color: #f2f2f2;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 260px;
    margin-top: 10px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    color: #1e88e5;
    margin: 0;
  }

  /* #04AA6D */
  
  .card-content {
    font-size: 14px;
    margin-top: 8px;
  }

  @media (max-width: 400px) {
    .card {
        background-color: #f2f2f2;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 16px;
        width: 90%;
        margin-left: -10px;
        /* display: flex; */
      }
  }

`, "",{"version":3,"sources":["webpack://./src/components/Tables/card.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,SAAS;EACX;;EAEA,YAAY;;EAEZ;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE;QACI,yBAAyB;QACzB,kBAAkB;QAClB,wCAAwC;QACxC,aAAa;QACb,UAAU;QACV,kBAAkB;QAClB,mBAAmB;MACrB;EACJ","sourcesContent":[".card {\n    background-color: #f2f2f2;\n    border-radius: 4px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    padding: 16px;\n    width: 260px;\n    margin-top: 10px;\n  }\n  \n  .card-title {\n    font-size: 18px;\n    font-weight: bold;\n    color: #1e88e5;\n    margin: 0;\n  }\n\n  /* #04AA6D */\n  \n  .card-content {\n    font-size: 14px;\n    margin-top: 8px;\n  }\n\n  @media (max-width: 400px) {\n    .card {\n        background-color: #f2f2f2;\n        border-radius: 4px;\n        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n        padding: 16px;\n        width: 90%;\n        margin-left: -10px;\n        /* display: flex; */\n      }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
