// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
    margin-top: 30px;
    display: flex;
    /* justify-content: space-between; */
    margin-left: 57px;
    gap: 33px;
    /* Additional flex properties can be added here */
}

.block1 {
    order:1;
}

.block2 {
    order:2;
}

.block3 {
    order:3;
}


/* Media query for tablet-sized screens */
@media only screen and (max-width: 768px) {
    .flex-container {
        flex-direction: column; /* Change flex direction to column for tablets */
        margin-left: 45px;
        margin-top: 10px;
        gap: 33px;
    }
}

/* Media query for mobile-sized screens */
@media only screen and (max-width: 480px) {
    .flex-container {
        flex-direction: column; /* Change flex direction to column for mobile */
        margin-top: 10px;
        margin-left: 0px;
    }

    .block1 {
        order:1;
    }
    
    .block2 {
        order:3;
    }
    
    .block3 {
        order:2;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Pages/main.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,oCAAoC;IACpC,iBAAiB;IACjB,SAAS;IACT,iDAAiD;AACrD;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,OAAO;AACX;;;AAGA,yCAAyC;AACzC;IACI;QACI,sBAAsB,EAAE,gDAAgD;QACxE,iBAAiB;QACjB,gBAAgB;QAChB,SAAS;IACb;AACJ;;AAEA,yCAAyC;AACzC;IACI;QACI,sBAAsB,EAAE,+CAA+C;QACvE,gBAAgB;QAChB,gBAAgB;IACpB;;IAEA;QACI,OAAO;IACX;;IAEA;QACI,OAAO;IACX;;IAEA;QACI,OAAO;IACX;AACJ","sourcesContent":[".flex-container {\n    margin-top: 30px;\n    display: flex;\n    /* justify-content: space-between; */\n    margin-left: 57px;\n    gap: 33px;\n    /* Additional flex properties can be added here */\n}\n\n.block1 {\n    order:1;\n}\n\n.block2 {\n    order:2;\n}\n\n.block3 {\n    order:3;\n}\n\n\n/* Media query for tablet-sized screens */\n@media only screen and (max-width: 768px) {\n    .flex-container {\n        flex-direction: column; /* Change flex direction to column for tablets */\n        margin-left: 45px;\n        margin-top: 10px;\n        gap: 33px;\n    }\n}\n\n/* Media query for mobile-sized screens */\n@media only screen and (max-width: 480px) {\n    .flex-container {\n        flex-direction: column; /* Change flex direction to column for mobile */\n        margin-top: 10px;\n        margin-left: 0px;\n    }\n\n    .block1 {\n        order:1;\n    }\n    \n    .block2 {\n        order:3;\n    }\n    \n    .block3 {\n        order:2;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
